import React,{useState,useEffect} from "react"
import { navigate} from "gatsby"
import LoaderSVG from '../images/loading.svg'
import { Box, Input, Button, Tabs, TabList, TabPanels, Tab, TabPanel, InputGroup, InputRightElement, Text} from "@chakra-ui/react"
import axios  from 'axios';
import { loadStripe } from '@stripe/stripe-js';

// https://www.9lessons.info/2017/10/reactjs-php-restful-api-token.html

// DB
// vfwapmas_pachisecret
// vfwapmas_pachisecret
// #HQ}_G-Z~`jRx|L7+IED%

const stripePromise = loadStripe('pk_test_51IthjfKIa0EKLo2u8G9X70zTj7Hlpp4hV8LnnQj8EZrAp3jR4zy9zaRlYiHgN1NNU5Z5YFtFFwftnG23rkRAEeNR00G8SOHUvK');

export const isBrowser = typeof window !== "undefined"



//自分のDBに接続
export const PostData = (type, userData) => {
  let postdata = Object.assign({type:type}, userData);

  return axios.post('https://apitest.yt-search.net/index.php', postdata)
    .then( (res) => {
      // console.log(res);
      return res;
    })
    .catch( (error) => {
      return error;
  });
}


export const login = (email,password) => {
  return new Promise(resolve => {
    if(isBrowser){
      if(email && password){
        var data = {
          email:email,
          password:password
        }
        PostData('login',data).then( (result) => {
          let responseData = result.data;
          resolve (responseData);
          // if(responseData.error){
          //   return JSON.stringify(responseData.error.text);
          // }
          // window.sessionStorage.setItem('userData',JSON.stringify(responseData.userData));
          // window.sessionStorage.setItem('token',JSON.stringify(responseData.token));
          // return "OK";
        });
      }
      else
      {
        let res = {
          error:{
            text:"入力してください"
          }
        }
        resolve (res);
        // return "入力してください";
      }
    }
  });
}

export const signup = (email,password) => {
  
  return new Promise(resolve => {
    if(isBrowser){
      if(email && password){
        var data = {
          email:email,
          password:password
        }

        PostData('signup', data).then( (result) => {
          let responseData = result.data;
          resolve (responseData);
          // if(responseData.error){
          //   // console.log(responseData.error.text);
          //   resolve (responseData.error.text);
          // }else{
          //   // console.log(responseData);
          //   resolve (responseData.stripe_url);
          // }
        });
      }
      else
      {
        let res = {
          error:{
            text:"入力してください"
          }
        }
        resolve (res);
      }
    }
  });
}

export const test = () => {
  console.log("111");
}

export const kakin = async () => {
  if(window.sessionStorage.getItem('userData')){
    let sitem = JSON.parse(window.sessionStorage.getItem('userData'));
    if(sitem.email){
      //stripe課金
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        lineItems: [{
          price: 'price_1IthqdKIa0EKLo2uKab3YKGb', // Replace with the ID of your price
          quantity: 1,
        }],
        mode: 'subscription',
        successUrl: 'http://localhost:8000/stripesuccess?session_id={CHECKOUT_SESSION_ID}',//stripe課金成功
        cancelUrl: 'http://localhost:8000/stripecancel/',//stripe課金失敗
        customerEmail: sitem.email,
        clientReferenceId: sitem.email,
      });
    }
  }
}

export const getemail = () => {
  if(window.sessionStorage.getItem('userData')){
    let sitem = JSON.parse(window.sessionStorage.getItem('userData'));
    if(sitem.email){
      return sitem.email;
    }
  }
  return null;
}

export const portal = () => {
  // curl https://api.stripe.com/v1/billing_portal/sessions \
  // -u sk_test_51IthjfKIa0EKLo2u0gLlDxyF26pcuRNRfaYOVwin7OSJkDNJeLdOmzwrmqnR31ciGUCmutsvbUB5WMA8myUJaPbN00A6741YJE: \
  // -d customer=cus_JpJLMJa6a6Fz9q \
  // -d return_url="https://example.com/account"
  if(window.sessionStorage.getItem('userData')){
    let sitem = JSON.parse(window.sessionStorage.getItem('userData'));
    if(sitem.stripe_customer){
      var options = {
        method: 'POST',
        url: 'https://api.stripe.com/v1/billing_portal/sessions',
        headers: {
          Authorization: 'Bearer ' + 'sk_test_51IthjfKIa0EKLo2u0gLlDxyF26pcuRNRfaYOVwin7OSJkDNJeLdOmzwrmqnR31ciGUCmutsvbUB5WMA8myUJaPbN00A6741YJE', 
          'content-type': 'application/x-www-form-urlencoded'
        },
        params: {
          customer: sitem.stripe_customer,
          return_url: 'http://localhost:8000/',
        },
      };
      axios.request(options)
      .then( (response) => {
        console.log(response.data.url);
        navigate(response.data.url);
      }
      ).catch(function (error) {
        console.error(error);
      });
    }
  }
}

export const logout = () => {
  window.sessionStorage.removeItem('userData');
  window.sessionStorage.removeItem('token');
}

export const isAuthenticated = () => {
  if(isBrowser){
    if(window.sessionStorage.getItem('token')){
      let titem = JSON.parse(window.sessionStorage.getItem('token'));
      if(Math.floor( new Date().getTime() / 1000 ) < titem.expiresAt){
        if(window.sessionStorage.getItem('userData')){
          let sitem = JSON.parse(window.sessionStorage.getItem('userData'));
          if(sitem.stripe_status == "active"){
            return "true";
          }
          return "notpaid";
        }
      }
    }
  }
  return "false";
}

export const getSecret = () => {
  if(window.sessionStorage.getItem('userData')){
    let sitem = JSON.parse(window.sessionStorage.getItem('userData'));
    console.log(sitem);
    if(sitem.stripe_paymentstatus == "paid"){
      let req_state = {
        token:sitem.token,
        user_id:sitem.user_id,
        post_id:46596,
      }
      PostData('SecretDB',req_state).then((result) => {
        let responseData = result.data;
        return JSON.stringify(responseData);
      });
    }else{
      return "mikakinn";
    }
  }
}


const SignupLoginPage = ({path}) => {  
  const [responseText, setResponseText] = useState("");
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const [state, setState] = useState({
    email: '',
    password: '',
    redirectToReferrer: false
  })

  const onChange = (e) => {
    setState({...state,[e.target.name]:e.target.value});
    setResponseText("");
  }
  
  if(isAuthenticated() == "true"){

    return(
      <Box>
        ログイン中
        <Button onClick={logout}>ログアウト</Button>
        <Button onClick={getSecret}>秘密取得</Button>
        <Button onClick={portal}>情報</Button>
        <Box>
          <Text>
            {responseText}
          </Text>
        </Box>
      </Box>
    )

  }else if(isAuthenticated() == "notpaid"){

    return(
      <Box>
        ログイン中(未課金)
        <Button onClick={logout}>ログアウト</Button>
        <Button onClick={getSecret}>秘密取得</Button>
        <Button onClick={kakin}>課金</Button>
        <Box>
          <Text>
            {responseText}
          </Text>
        </Box>
      </Box>
    )

  }else{

    return(
      <Tabs>
        <TabList>
          <Tab>ログイン</Tab>
          <Tab>新規登録</Tab>
        </TabList>

        <TabPanels>

          <TabPanel>
            <p>ログイン</p>
            <InputGroup size="md">
              <Input placeholder="Basic usage" name="email" onChange={onChange}/>
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Enter password"
                name="password"
                onChange={onChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Button onClick={login}>ログイン</Button>
            <Button onClick={portal}>portal</Button>
            <Box>
              <Text>
                {responseText}
              </Text>
            </Box>
          </TabPanel>

          <TabPanel>
            <p>新規登録</p>
            <InputGroup size="md">
              <Input placeholder="Basic usage" name="email" onChange={onChange}/>
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Enter password"
                name="password"
                onChange={onChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
                </InputRightElement>
            </InputGroup>
            <Button onClick={signup}>新規登録</Button>
            <Box>
              <Text>
                {responseText}
              </Text>
            </Box>
          </TabPanel>

        </TabPanels>
      </Tabs>
    )
  }
  
}



//STRIPEからデータを確認　payment_status === "paid" を確認
const getStripeCustomerDataByCustomerId = (customerId) => {
  
  var options = {
    method: 'GET',
    url: 'https://api.stripe.com/v1/customers/' + customerId + '/subscriptions' ,
    headers: {
      Authorization: 'Bearer ' + 'sk_test_51IthjfKIa0EKLo2u0gLlDxyF26pcuRNRfaYOVwin7OSJkDNJeLdOmzwrmqnR31ciGUCmutsvbUB5WMA8myUJaPbN00A6741YJE', 
      'content-type': 'application/json'
    },
  };

  return axios.request(options)
    .then( (response) => {
      let responseData = response.data;
      return (responseData);
    })
    .catch( (error) => {
      return (error.response);
    });
}

//自分のDBからユーザーデータを取得
const getUserDataFromDB = (email, password) => {
  var data = {
    type:"getUserData",
    email:email,
    password:password
  }
  return axios.post('https://apitest.yt-search.net/index.php', data)
    .then( (response) => {
      let responseData = response.data;
      return (responseData);
    })
    .catch( (error) => {
      console.log(error);
      return (error);
    });
}

//自分のDBからメールアドレスが存在するか確認
const valifyMailFromDB = (email) => {
  var data = {
    type:"valifyMail",
    email:email,
  }
  return new Promise( (resolve, reject) =>{
    axios.post('https://apitest.yt-search.net/index.php', data)
    .then( (response) => {
      let responseData = response.data;
      resolve(responseData);
    })
    .catch( (error) => {
      console.log(error);
      reject(error);
    });
  });
}

//自分のDBからユーザーデータを確認
const getJWTFromDB = (email, id) => {
  var data = {
    type:"getJWT",
    email:email,
    id:id
  }
  return new Promise( (resolve, reject) =>{
    axios.post('https://apitest.yt-search.net/index.php', data)
    .then( (response) => {
      let responseData = response.data;
      resolve(responseData);
    })
    .catch( (error) => {
      // console.error(error);
      reject(error);
    });
  });
}